<template>
  <div class="p-6 pr-6 all">
    <popupCharge
      :activation="charge"
      v-if="charge"
    />
    <success
      :activation="activeSuccess"
      v-if="activeSuccess"
      :message="message"
      @oga="backSuccess"
    />
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-1/2 flex text-left text-c22 items-center font-c8">
          <icon
            :data="icons.back"
            height="40"
            width="40"
            class="mr-4 cursor-pointer"
            original
            @click="goBack"
          />
          <div class="">
            Nouveau programme
          </div>
        </div>
      </div>

      <div class="mt-6 bg-white p-12 flex">
        <div class="w-1/6 pr-16">
          <upload
            height="116px"
            background="linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #D7E0EA"
            radius="4.83871px"
            border="null"
          />
        </div>

        <div class="w-5/6">
          <div class="w-full flex">
            <div class="w-1/2">
              <div class="text-left text-83 text-c14">
                Nom
              </div>
              <inputo
                class="w-full mt-2"
                height="46px"
                radius="8px"
                size="16px"
                border="1px solid #EDEDEE"
                @info="backName"
              />
            </div>

            <div class="w-1/2 ml-10">
              <div class="text-left text-83 text-c14">
                Email
              </div>
              <inputo
                class="w-full mt-2"
                height="46px"
                radius="8px"
                size="16px"
                border="1px solid #EDEDEE"
                type="email"
                @info="backEmail"
              />
            </div>
          </div>

          <div class="w-full flex mt-4">
            <div class="w-1/2">
              <div class="text-left text-83 text-c14">
                Téléphone
              </div>
              <phoneNumber
                class="w-full mt-2"
                height="46px"
                radius="8px"
                size="16px"
                border="1px solid #EDEDEE"
                @info="backPhone"
              />
            </div>

            <div class="w-1/2 ml-10">
              <div class="text-left text-83 text-c14">
                Adresse
              </div>
              <inputo
                class="w-full mt-2"
                height="46px"
                radius="8px"
                size="16px"
                border="1px solid #EDEDEE"
                @info="backAdresse"
              />
            </div>
          </div>

          <div class="mt-16 text-6b font-c7 text-c16 text-left">
            Administrateur
          </div>

          <div class="w-full flex mt-4">
            <div class="w-1/2">
              <div class="text-left text-83 text-c14">
                Nom
              </div>
              <inputo
                class="w-full mt-2"
                height="46px"
                radius="8px"
                size="16px"
                border="1px solid #EDEDEE"
                @info="backAdminFirstName"
              />
            </div>

            <div class="w-1/2 ml-10">
              <div class="text-left text-83 text-c14">
                Prenom
              </div>
              <inputo
                class="w-full mt-2"
                height="46px"
                radius="8px"
                size="16px"
                border="1px solid #EDEDEE"
                @info="backAdminLastName"
              />
            </div>
          </div>

          <div class="w-full flex mt-4">
            <div class="w-1/2">
              <div class="text-left text-83 text-c14">
                Email
              </div>
              <inputo
                class="w-full mt-2"
                height="46px"
                radius="8px"
                size="16px"
                border="1px solid #EDEDEE"
                type="email"
                @info="backAdminEmail"
              />
            </div>

            <div class="w-1/2 ml-10" />
          </div>

          <div
            v-if="error"
            class="text-center text-red mt-10"
          >
            {{ error }}
          </div>

          <div class="flex justify-end mt-20">
            <buton
              label="Annuler"
              height="51px"
              weight="700"
              size="18px"
              radius="6px"
              color="black"
              background="#EDEDEE"
              class="w-1/4"
            />

            <buton
              label="Enregistrer"
              height="51px"
              weight="700"
              size="18px"
              radius="6px"
              background="#31B049"
              class="w-1/4 ml-6"
              @info="verification"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import upload from '../../component/helper/form/upload2'
import inputo from '../../component/helper/form/input'
import phoneNumber from '../../component/helper/form/phoneNumber'
import buton from '../../component/helper/add/button'
import apiroutes from '../../router/api-routes'
import http from '../../plugins/https'
import popupCharge from '../../component/popup/popupCharge'
import success from '../../component/popup/success'

export default {
  name: "Index",

  components: {
    buton,
    inputo,
    upload,
    phoneNumber,
    popupCharge,
    success
  },

  data () {
    return {
      recherche: null,
      icons: {
        back
      },
      response: {},
      admin: {
        role: [],
        pictureId: "",
        phoneNumber: null
      },
      charge: false,
      error: null,
      activeSuccess: false,
      message: 'Nouveau programme créé avec succès'
    }
  },

  created () {
  },

  methods: {
    retourSearch (answer) {
      this.recherche = answer
    },

    backSuccess (a) {
      this.activeSuccess = false
      this.$router.push('partenaires')
    },

    backFile (a) {
      // this.response.file = a
    },

    backName (a) {
      this.response.name = a
    },

    backAdresse (a) {
      this.response.localisation = a
    },

    backEmail(a) {
      this.response.email = a
    },

    backPhone (a) {
      this.response.phoneNumber = a
    },

    backAdminFirstName (a) {
      this.admin.firstName = a
    },

    backAdminLastName (a) {
      this.admin.lastName = a
    },

    backAdminEmail (a) {
      this.admin.email = a
    },

    goBack () {
      window.history.back()
    },

    verification () {
      if (this.response.name &&
          this.response.phoneNumber &&
          this.response.localisation &&
          this.admin.email &&
          this.admin.firstName
      ) {
        this.saveSfd()
      } else {
        this.error = 'Remplissez les champs du formulaire pour continuer'
      }
    },

    saveSfd () {
      this.error = null
      this.charge = true
      http.post(apiroutes.baseURL + apiroutes.createSfd, this.response)
          .then(response => {
            // console.log(response)
            this.admin.sfdId = response.id
            this.saveAdminSfd()
          })
          .catch(error => {
            this.charge = false
            // console.log(error)
          })
    },

    saveAdminSfd () {
      http.post(apiroutes.baseURL + apiroutes.createSfdAdmin, this.admin)
          .then(response => {
            // console.log(response)
            this.charge = false
            this.activeSuccess = true
          })
          .catch(error => {
            this.charge = false
            // console.log(error)
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.all{
  min-height: 100vh;
}
</style>
